import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const path = `${configService.config.apiUrl}/activity`

const client = apiClient.build(path)

client.forEntity = (id, options) => {
  const searchPath = `${path}/entity/${id}`
  return client.__api
    .get(searchPath, { params: options })
    .then(response => response)
}

export default client
